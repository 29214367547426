<template>
  <div>
    <hero></hero>
    <multi-reports></multi-reports>
    <extra-features></extra-features>
    <tour3></tour3>
    <programs3></programs3>
    <l-footer></l-footer>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>